import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SearchBar } from '../../../../../components/Search';
import { getRoom } from '../../../../../utils/variables';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { SearchFeedback } from '../../../../../components/SearchFeedback';
import { Loading } from '../../../../../components/Loading';
import { ContentTableTypes, ContentTables } from '../../../../../components/ContentTable';
import { BackButton } from '../../../../../components/Atoms';
import { EmptyContentSearchResults } from './EmptyContentSearchResults';
import { getParam, setParam } from '../../../../../utils/urlParams';
import { useContent } from '../../../../../services/Content/getContent';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { SearchBarNew } from '../../../../../components/NewFilters';
import { capitalizeFirstLetter } from '../../../../../utils/text';

export const MatchesPage = () => {
    const room = getRoom();
    const location = useLocation();
    const { isThreat, value, type, appliedFilter } = location.state || {};
    const page = getParam('page') || 1;
    const limit = 10;
    const [offset, setOffsetState] = useState((parseInt(page, 10) - 1) * 10);
    const setOffset = (newOffset) => {
        setOffsetState(newOffset);
        setParam('page', (newOffset / limit) + 1);
    };
    const filters = useSelector((state) => state.filters.filters);
    const initialFiltersRef = useRef(filters);
    const defaultFilters = { isThreat: isThreat || false, [type]: value };

    const [sort, setSort] = useState('score');
    const {
        data: contents,
        count,
        loading,
        error
    } = useContent({
        filters: (value && type) ? defaultFilters : { isThreat: isThreat || false },
        limit,
        skip: offset,
        sort,
        fetchPolicy: 'no-cache',
        skipRequest: !room.project_id,
        name: 'getMatches',
        useGlobalFilters: true
    });

    const sortApplied = (sortNum) => {
        const sortOptions = [
            'date',
            'engagement',
            'score'
        ];
        setSort(sortOptions[sortNum - 1]);
        setOffset(0);
    };

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Overview', href: `/situation-rooms/${room.id}/overview` },
        { title: 'Matches' }
    ];
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    useEffect(() => {
        const initialFilters = initialFiltersRef.current;
        if (appliedFilter && initialFilters) {
            let updatedFilters = {};
            if (Object.keys(appliedFilter).includes('sources')) {
                updatedFilters = { ...initialFilters, sources: [], sourcesWithDomains: [], contentType: [] };
            } else if (Object.keys(appliedFilter).includes('date')) {
                updatedFilters = { ...initialFilters, date: { startDate: 0, endDate: 0 }, dateRange: {} };
            } else {
                const key = Object.keys(appliedFilter);
                updatedFilters = { ...initialFilters, [key[0]]: [] };
            }
            sessionStorage.setItem('initialFilters', JSON.stringify(updatedFilters));
        } if (initialFilters && !appliedFilter) {
            return sessionStorage.setItem('initialFilters', JSON.stringify(initialFilters));
        }
    }, [appliedFilter]);

    return (
        <div data-testid="matches-page">
            <div className="page-padding">
                <BreadCrumbs items={bread} />
                <BackButton pathName={`/situation-rooms/${room.id}/overview`}
                    betaFilters={betaFiltersEnabled}
                    prevFilters={initialFiltersRef.current}
                    appliedFilter={appliedFilter}
                />
                <div className="d-flex justify-content-between align-items-center mt-0 my-3">
                    <h2>Matches</h2>
                    {!loading ? (
                        <SearchFeedback totalCount={count} error={error} contentType={CONTENT_TYPE.MATCH} />
                    ) : ''}
                </div>
                {type && <h2>{capitalizeFirstLetter(type)} - {value}</h2>}
                <div className="mb-2">
                    {betaFiltersEnabled ? (
                        <SearchBarNew sortApplied={sortApplied}
                            score
                            sort
                            sortOptionKey="relevance"
                            overview
                            defaultFilters={(value && type) ? defaultFilters : { isThreat: isThreat || false }}
                            showBooleanSearch
                        />
                    )
                        : (
                            <SearchBar placeholder="Search - Enter a search term to update the threats and press ENTER"
                                name="Page Filters"
                                data={room}
                                sort
                                sortApplied={sortApplied}
                                score
                                showBooleanSearch
                            />
                        )}

                </div>
                {loading ? <Loading pointer={false} gif relative height={400} /> : (
                    <>
                        <ContentTables contents={contents}
                            pagination={{ offset, setOffset, count }}
                            tableType={ContentTableTypes.MATCHES}
                            contentType={CONTENT_TYPE.MATCH}
                            total={count}
                            contentsToDisplay={limit}
                        />
                        {!count && <div className="mt-4"><EmptyContentSearchResults /></div>}
                    </>
                )}
            </div>
        </div>
    );
};

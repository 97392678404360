import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import noDataAvailable from '../../assets/images/NoDataAvailable.png';

type NotFoundProps = {
    small: boolean
}

export const NotFound = ({ small = false }: NotFoundProps) => (
    <Container className={`${!small ? 'content-page' : ''} d-flex justify-content-center align-items-center error-page`}>
        <div className="text-center fbiframe">
            <img src={noDataAvailable} alt="Not found" />
            <h3 className="my-2">The page you’re looking for doesn’t exist.</h3>
            <p className="my-2" data-testid="notfound">
                Let’s guide you back to where you need to be—use the navigation
                above or return to the homepage.
            </p>
            <Link component={Button} to="/">
                Return to homepage
            </Link>
        </div>
    </Container>
);

import { useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useGlobalFiltersInClusters } from './GlobalFIltersInClusters';
import { GET_CLUSTERS_COUNT } from '../../graphql';

type UseTotalClustersProps = {
    appliedFilters?: any,
    onCompletedCall?: any,
    useGlobalFilters?: boolean,
    skip?: boolean
}

export const useTotalClusters = ({ appliedFilters, onCompletedCall, useGlobalFilters = true, skip = false }: UseTotalClustersProps) => {
    const filters = useGlobalFiltersInClusters({ useGlobalFilters });
    const { data, loading, refetch } = useQuery(GET_CLUSTERS_COUNT, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, appliedFilters?.filters || {}), ['labels'])
        },
        fetchPolicy: 'cache-and-network',
        skip,
        onCompleted: () => {
            if (onCompletedCall) { onCompletedCall(); }
        }
    });
    const activeFilters = Object.values(omit(merge(filters.filters, appliedFilters?.filters || {}), ['labels'])).filter(x => {
        if (!x) return false;
        if (typeof x === 'object') {
            if (x.startDate || x.endDate) return true;
            return false;
        }
        return true;
    });

    return {
        counts: data?.getClustersCount,
        loading,
        hasFilters: activeFilters.length > 0,
        refetchClustersCount: refetch
    };
};

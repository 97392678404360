import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { VolumeOverTimeTypePlot } from './VolumeOverTimePlot';
import { ClusterOrSubCluster, SubCluster } from '../../types';
import { addHighlights, useQueryKeywords } from '../../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../../utils/getHighlightedContent';
import { Engagement } from './ClusterStats/Engagement';
import { Content } from './ClusterStats/Content';
import { SentimentCard } from './ClusterStats/SentimentCard';
import { OriginalPoster } from './ClusterStats/OriginalPoster';
import { SubClusters } from './ClusterStats/SubClusters';
import { NarrativeTheme } from './ClusterStats/NarrativeTheme';
import { getParam, setParam, unsetParam } from '../../../../../utils/urlParams';
import { saveSelectedCluster } from '../../../store';
import { GET_CLUSTERS } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { CheckBox } from '../../../../../components/Form/CheckBox';

type VolumeAndEngagmentType = {
    Volume: boolean;
    Engagement: boolean;
}

type SummaryCommonProps = {
    setTab: (a: string) => void;
    parentClusterDetails: { id: string, title: string } | null
}

type SummaryProps<T extends boolean> = SummaryCommonProps & ClusterOrSubCluster<T>

export const Summary = <T extends boolean>({ cluster, isParent, setTab, parentClusterDetails }: SummaryProps<T>) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState<VolumeAndEngagmentType>({ Volume: true,
        Engagement: true });
    const room = getRoom();
    const keywordsToHighlight = useQueryKeywords('clusterFilters');
    const { highlightedText: description } = addHighlights({
        text: isParent ? cluster.description : cluster.subClusterSummary,
        keywordsToHighlight
    });
    const { data: clusterData } = useQuery(GET_CLUSTERS, {
        variables: {
            limit: 1,
            projectId: room.project_id,
            id: !isParent ? cluster?.topClustersMappedId : []
        },
        skip: !(!isParent && cluster?.topClustersMappedId)
    });
    const parentCluster = clusterData?.getClusters?.clusters ? clusterData?.getClusters?.clusters[0] : null;
    const parent = parentClusterDetails || parentCluster;
    const contentIds = isParent ? cluster?.subClusters.map((subCluster: SubCluster) => subCluster.contentIds).flat() : cluster?.contentIds;
    const handleClusterThemeCardClick = ({ id }: { id: string }) => {
        dispatch(saveSelectedCluster({
            selectedCluster: id,
            isParentCluster: true
        }));
        if (getParam('narrative')) {
            setParam('prev_narrative', getParam('narrative'));
            unsetParam('narrative');
            setParam('narrative-theme', id);
        }
    };
    const handleChange = (item: keyof VolumeAndEngagmentType) => {
        setShow((prevShow) => ({
            ...prevShow,
            [item]: !prevShow[item], // Toggle the boolean value of the selected item
        }));
    };
    const getGraphHeader = () => {
        if (show.Engagement && !show.Volume) return 'Engagement over time';
        if (!show.Engagement && show.Volume) return 'Volume over time';
        return 'Engagement and Volume over time';
    };
    return (
        <div className="bg-white p-2 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <p className="highlight-em w-75">{getHighlightedContent(description)}</p>
            <div className="cluster-stats-align mb-3">
                <Engagement count={cluster.engagement} disabled />
                {isParent && (
                    <SubClusters count={cluster.subClustersMappedId.length} setTab={setTab} />
                )}
                <Content count={cluster.volume} setTab={setTab} />
                <SentimentCard sentiments={cluster.sentimentJson}
                    averageSentiment={cluster.averageSentiment}
                    id={`sentiment_${cluster.id}`}
                />
                {!isParent && (

                    <OriginalPoster opCtype={cluster.originatorPostCtype}
                        opName={cluster.originatorPostName}
                        setTab={setTab}
                    />
                )}
                {parent && (
                    <Link to={`/situation-rooms/${room.id}/ai-narratives?narrative-theme=${parent.id}&tab=summary`}>
                        <NarrativeTheme title={parent.title}
                            subClusterId={cluster.id}
                            clusterId={parent.id}
                            onClick={handleClusterThemeCardClick}
                        />
                    </Link>
                )}
            </div>
            <hr className="my-3" />
            <div className="position-relative">
                <p className="font-weight-bold mb-2">{getGraphHeader()}</p>
                <div className="border">
                    <div className="p-2 grid-container-3 gap-2">
                        {Object.keys(show).map((item) => {
                            const key = item as keyof VolumeAndEngagmentType;
                            return (
                                <div className="d-flex border p-2">
                                    <CheckBox
                                        id={key}
                                        name={key}
                                        value={key}
                                        checked={show[key]}
                                        onChange={() => handleChange(key)}
                                        className={`checkbox-color-${item === 'Volume' ? 'green' : 'pink'}`}
                                    />
                                    <p className="mb-0 pt-3px">
                                        {item}
                                    </p>
                                </div>
                            );
                        })}

                    </div>
                    <hr className="mt-0" />
                    <div className="p-2">
                        <p className="mb-2">To zoom in on a specific period or date,
                            you can click and drag horizontally within the graph
                        </p>
                        <VolumeOverTimeTypePlot content={show.Volume ? cluster.volumeOverTime : []}
                            engagement={show.Engagement ? cluster?.engagementOverTime : []}
                            contentIds={contentIds}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

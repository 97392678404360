import React from 'react';
import { Col } from 'reactstrap';
import { Tag } from 'react-feather';
import { LabelDropdown } from '../../../../pages/Room/Labels';
import { getRoom } from '../../../../utils/variables';
import { WidgetName, WidgetTextTooltip } from '../../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';

type UserLabelsProps = {
    contentId: string
}

export const UserLabels = ({ contentId }: UserLabelsProps) => {
    const room = getRoom();
    return (
        <Col className="card content-view new-content-view threats mr-3 px-2 py-1 set-fit-content col-lg-4 col-xs-6 mb-1">
            <WidgetTextTooltip widgetName={WidgetName.Labels} classes="card-text title" />
            <div className="d-flex">
                <Tag size={24} />
                <LabelDropdown roomId={room?.id} contentId={contentId} count />
            </div>
        </Col>
    ); };

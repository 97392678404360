import { LabelsDataProps } from '../../../../pages/Room/Labels/InputLabelName';
import { allTypes } from '../../../../utils/lib/search';
import { getRoom } from '../../../../utils/variables';

type GatAllLabelsProps = {
    contentId: string
    userLabelsData: LabelsDataProps[]
    threatResolvers: { type: string, resolver: string }[]
}

export const getAllLabels = ({ contentId, userLabelsData, threatResolvers }: GatAllLabelsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;

    const actualLabels = allTypes.filter(type => {
        if (disabledThreatTypes.includes(type.key)) {
            return false;
        }
        const key = type.isResolver ? 'resolver' : 'type';
        return threatResolvers.find(resolver => resolver[key] === type.key);
    }).map(({ name }) => name);

    const allUserLabels = userLabelsData.filter((labelObject) => (labelObject.contentIds.includes(contentId) ? labelObject : false))
        .sort((current, next) => current.label.length - next.label.length)
        .map(({ label }) => label);
    if (threatSignalsEnabled) {
        return actualLabels;
    }
    return actualLabels.concat(allUserLabels);
};

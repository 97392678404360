import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clusteringStatus: null as null | { newThemes: number, newNarratives: number, completedAt: number, projectId: string }
};

export const aiNarrativesSlice = createSlice({
    name: 'aiNarratives',
    initialState,
    reducers: {
        setClusteringStatus: (state, action) => ({
            ...state,
            clusteringStatus: action.payload
        })
    }
});

export const { setClusteringStatus } = aiNarrativesSlice.actions;
export const aiNarratives = aiNarrativesSlice.reducer;

// TODO: move over narrative redux states to this file

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { processDateRange } from '../../utils/getTimeFrom';
import { getParam, setParam, unsetParam } from '../../utils/urlParams';

export const saveFiltersToLocalStorage = (roomId: string, filters: any) => {
    const savedFilters = JSON.stringify(filters);
    localStorage.setItem(`filters_${roomId}`, savedFilters);
};

export const removeFiltersFromLocalStorage = (roomId: string) => {
    localStorage.removeItem(`filters_${roomId}`);
};

const paramDateRange = getParam('daterange');
const isValidDateRange = paramDateRange && paramDateRange.split(',').length === 2;

const defaultFilters = {
    contentType: [],
    dateRange: {
        startDate: moment().subtract(6, 'days').unix(),
        endDate: moment().unix()
    },
    threatTypes: [],
    sentiment: []
};

const dateRangeInitialState = isValidDateRange ? {
    startDate: parseInt(paramDateRange.split(',')[0], 10),
    endDate: parseInt(paramDateRange.split(',')[1], 10)
} : defaultFilters.dateRange;

const getURLParamArray = (field: string) => {
    const urlParam = getParam(field);
    return urlParam ? urlParam.split(',') : [];
};

const defaultThreatTypes = getURLParamArray('threat_types');
if (defaultThreatTypes.includes('deep-toxic-classifier')) {
    defaultThreatTypes.splice(defaultThreatTypes.indexOf('deep-toxic-classifier'), 1);
    defaultThreatTypes.push(...['ATTACK_ON_COMMENTER', 'ATTACK_ON_AUTHOR',
        'IDENTITY_ATTACK', 'INSULT', 'INFLAMMATORY', 'TOXICITY', 'SEVERE_TOXICITY', 'THREAT']);
}

export const initialState = {
    isBooleanOpen: false,
    isFiltersOpen: false,
    filters: {
        dateRangeOption: 'all',
        dateRange: dateRangeInitialState,
        sources: [],
        domains: [],
        origin: [],
        sentiment: getURLParamArray('sentiments'),
        threatTypes: defaultThreatTypes,
        threatResolvers: [],
        query: getParam('query') || '',
        booleanQuery: '',
        booleanQueryKeywords: [],
        sourcesWithDomains: [],
        languages: [],
        convertedBooleanQuery: '',
        contentType: getURLParamArray('ctypes'),
        from: [],
        keywords: [],
        ids: [],
        labels: [],
        entities: [],
        hashtags: [],
        mentions: []
    },
};
const selectedFiltersInitialState = {
    isFiltersOpen: false,
    filters: {
        dateRangeOption: 'all',
        dateRange: dateRangeInitialState,
        sources: [],
        domains: [],
        origin: [],
        sentiment: getURLParamArray('sentiments'),
        threatTypes: defaultThreatTypes,
        query: '',
        booleanQuery: '',
        booleanQueryKeywords: [],
        sourcesWithDomains: [],
        languages: [],
        convertedBooleanQuery: '',
        contentType: getURLParamArray('ctypes'),
        from: [],
        keywords: [],
        ids: [],
        labels: [],
        entities: [],
        hashtags: [],
        mentions: [],
        queryName: ''
    },
};
const customNarrativesInitialState = {
    topics: [],
    selectedTopic: {
        id: '',
        threat: '',
        boolean: '',
        keywords: [],
        query_type: '',
        dslQuery: '',
        isSaved: '',
        __typename: ''
    },
    queryErrorList: [],
};
const keywordsInitialState = {
    highlightKeywords: [],
};
const removeContent = {
    sort: { key: 'ALL', name: 'All' },
    searchValue: ''
};
const selectedClusterParam = getParam('narrative-theme');
const selectedSubCluster = getParam('narrative');
const selectedClusterInitialState = {
    selectedCluster: selectedClusterParam || selectedSubCluster || '',
    isParentCluster: !!selectedClusterParam,
    hasDuplicatesFilter: getParam('duplicates') === 'true' || getParam('hasDuplicates') === 'true'
};
const clusterFiltersIntitialState = {
    filters: {
        dateRangeOption: 'all',
        dateRange: { startDate: 0, endDate: 0 },
        sources: [],
        domains: [],
        origin: [],
        sentiment: [],
        threatTypes: [],
        query: '',
        sourcesWithDomains: [],
        languages: [],
        contentType: [],
        from: [],
        keywords: [],
        ids: [],
        labels: [],
        entities: [],
        hashtags: [],
        mentions: []
    },
};
const setActiveTabInitialState = {
    activeTab: '',
    isContentPage: false,
    isListViewEnabled: getParam('view') !== 'chart'
};
const activeAiClusteringTableViewInitialState = {
    viewMode: ''
};

const isComparisonsPage = window.location.pathname.includes('-comparison');
const isParent = window.location.pathname.includes('narrative-theme');
const comparisonIds = getParam('id')?.split(',')?.map(id => ({ id, title: '' })) || [];

const narrativeComparisonInitialState = {
    selectedNarratives: (isComparisonsPage && !isParent) ? comparisonIds : []
};
const narrativeThemeComparisonInitialState = {
    selectedNarrativeThemes: (isComparisonsPage && isParent) ? comparisonIds : []
};
const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        saveFilters: (state, { payload }) => {
            let dateRange;
            const newPayload = { ...payload };

            if (payload.dateRangeOption) {
                dateRange = processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                });
            } else if (payload.dateRange) {
                setParam('daterange', `${payload.dateRange.startDate},${payload.dateRange.endDate}`);
                dateRange = {
                    startDate: payload.dateRange.startDate,
                    endDate: payload.dateRange.endDate
                };
            }
            const updatedFilters = {
                ...state.filters,
                ...newPayload,
                dateRange: dateRange || state.filters.dateRange,
            };
            if ('roomId' in payload) {
                const { roomId } = payload;
                saveFiltersToLocalStorage(roomId, updatedFilters);
            }
            delete newPayload.roomId;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...newPayload,
                    dateRange: dateRange || state.filters.dateRange,
                }
            };
        },
        resetFilters: (state, { payload }) => {
            if ('roomId' in payload) {
                const { roomId } = payload;
                removeFiltersFromLocalStorage(roomId);
            }
            return {
                ...state,
                filters: {
                    ...initialState.filters,
                    ...defaultFilters
                }
            };
        },
        openBooleanFilters: (state) => ({
            ...state,
            isBooleanOpen: true
        }),
        closeBooleanFilters: (state) => ({
            ...state,
            isBooleanOpen: false,
            filters: {
                ...state.filters,
                convertedBooleanQuery: state.filters.booleanQuery ? state.filters.convertedBooleanQuery : '',
                booleanQueryKeywords: state.filters.booleanQuery ? state.filters.booleanQueryKeywords : []
            }
        }),
        openFilters: (state) => ({
            ...state,
            isFiltersOpen: true,
        }),
        closeFilters: (state) => ({
            ...state,
            isFiltersOpen: false
        })
    }
});
const selectedFiltersSlice = createSlice({
    name: 'selectedFilters',
    initialState: selectedFiltersInitialState,
    reducers: {
        saveSelectedFilters: (state, { payload }) => {
            let dateRange;

            if (payload.dateRangeOption) {
                dateRange = processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                });
            } else if (payload.dateRange) {
                dateRange = {
                    startDate: payload.dateRange.startDate,
                    endDate: payload.dateRange.endDate
                };
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    dateRange: dateRange || state.filters.dateRange,
                }
            };
        },
        resetSelectedFilters: (state) => ({
            ...state,
            filters: {
                ...selectedFiltersInitialState.filters,
                ...defaultFilters
            }
        })
    }
});
const clusterFiltersSlice = createSlice({
    name: 'clusterFilters',
    initialState: clusterFiltersIntitialState,
    reducers: {
        saveClusterFilters: (state, { payload }) => {
            let dateRange;

            if (payload.dateRangeOption) {
                dateRange = processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                });
            } else if (payload.dateRange) {
                dateRange = {
                    startDate: payload.dateRange.startDate,
                    endDate: payload.dateRange.endDate
                };
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    dateRange: dateRange || state.filters.dateRange,
                }
            };
        },
        resetClusterFilters: (state) => ({
            ...state,
            filters: {
                ...clusterFiltersIntitialState.filters
            }
        })
    }
});
const watchlistFiltersSlice = createSlice({
    name: 'watchlistFilters',
    initialState,
    reducers: {
        saveWatchlistFilters: (state, { payload }) => {
            let dateRange;

            if (payload.dateRangeOption) {
                dateRange = processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                });
            } else if (payload.dateRange) {
                dateRange = {
                    startDate: payload.dateRange.startDate,
                    endDate: payload.dateRange.endDate
                };
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    dateRange: dateRange || state.filters.dateRange,
                }
            };
        },
        resetWatchlistFilters: (state) => ({
            ...state,
            filters: {
                ...initialState.filters
            }
        }),
    }
});

const customNarrativesSlice = createSlice({
    name: 'customNarratives',
    initialState: customNarrativesInitialState,
    reducers: {
        saveCustomNarratives: (state, { payload }) => ({
            topics: payload.topics || state.topics,
            selectedTopic: payload.selectedTopic || state.selectedTopic,
            queryErrorList: payload.queryErrorList || state.queryErrorList,
        }),
        resetCustomNarratives: () => customNarrativesInitialState
    }

});
const roomPreviewKeywordsSlice = createSlice({
    name: 'roomPreviewKeywords',
    initialState: keywordsInitialState,
    reducers: {
        saveRoomPreviewKeywords: (state, { payload }) => ({
            highlightKeywords: payload.highlightKeywords || state.highlightKeywords,
        })
    }
});
const removeContentSlice = createSlice({
    name: 'removeContentFilter',
    initialState: removeContent,
    reducers: {
        saveSort: (state, { payload }) => ({
            ...state,
            sort: payload.sort || state.sort
        }),
        saveFilter: (state, { payload }) => ({
            ...state,
            searchValue: payload.searchValue || state.searchValue
        }),
        resetAll: () => removeContent
    }
});

const selectedClusterSlice = createSlice({
    name: 'selectedCluster',
    initialState: selectedClusterInitialState,
    reducers: {
        saveSelectedCluster: (state, { payload }) => ({
            selectedCluster: payload.selectedCluster !== undefined ? payload.selectedCluster : state.selectedCluster,
            isParentCluster: payload.isParentCluster !== undefined ? payload.isParentCluster : state.isParentCluster,
            hasDuplicatesFilter: payload.hasDuplicatesFilter !== undefined ? payload.hasDuplicatesFilter : state.hasDuplicatesFilter
        }),
        resetSelectedCluster: () => {
            unsetParam('narrative-theme');
            unsetParam('narrative');
            unsetParam('tab');
            return {
                selectedCluster: '',
                isParentCluster: false,
                hasDuplicatesFilter: false
            };
        }
    }
});

const activeTabSlice = createSlice({
    name: 'activeTab',
    initialState: setActiveTabInitialState,
    reducers: {
        setActiveTab: (state, { payload }) => ({
            activeTab: payload.activeTab !== undefined ? payload.activeTab : state.activeTab,
            isContentPage: payload.isContentPage !== undefined ? payload.isContentPage : state.isContentPage,
            isListViewEnabled: payload.isListViewEnabled !== undefined ? payload.isListViewEnabled : state.isListViewEnabled
        })
    }
});

const activeAiClusteringTableViewSlice = createSlice({
    name: ' activeAiClusteringTableView',
    initialState: activeAiClusteringTableViewInitialState,
    reducers: {
        setActiveListView: (state, { payload }) => ({
            viewMode: payload.viewMode || state.viewMode
        })
    }
});

const narrativeComparisonSlice = createSlice({
    name: 'narrativeComparison',
    initialState: narrativeComparisonInitialState,
    reducers: {
        setNarrativesComparisonList: (state, { payload }) => {
            const selectedNarratives = payload.selectedNarratives || state.selectedNarratives;
            const ids = selectedNarratives.map((narrative: any) => narrative.id);
            if (window.location.pathname.includes('narrative-comparison')) {
                setParam('id', ids.join(','));
            }
            return ({
                selectedNarratives
            });
        },
        resetNarrativeList: () => ({
            selectedNarratives: []
        })
    }
});

const narrativeThemeComparisonSlice = createSlice({
    name: 'narrativeThemeComparison',
    initialState: narrativeThemeComparisonInitialState,
    reducers: {
        setNarrativesThemeComparisonList: (state, { payload }) => {
            const selectedNarrativeThemes = payload.selectedNarrativeThemes || state.selectedNarrativeThemes;
            const ids = selectedNarrativeThemes.map((narrative: any) => narrative.id);
            if (window.location.pathname.includes('narrative-theme-comparison')) {
                setParam('id', ids.join(','));
            }
            return ({
                selectedNarrativeThemes
            });
        },
        resetNarrativeThemeList: () => ({
            selectedNarrativeThemes: []
        })
    }
});

export const { saveFilters, resetFilters, openBooleanFilters, closeBooleanFilters, openFilters, closeFilters } = filtersSlice.actions;
export const filters = filtersSlice.reducer;

export const { saveSelectedFilters, resetSelectedFilters } = selectedFiltersSlice.actions;
export const selectedFilters = selectedFiltersSlice.reducer;

export const { saveWatchlistFilters, resetWatchlistFilters } = watchlistFiltersSlice.actions;
export const watchlistFilters = watchlistFiltersSlice.reducer;
export const { saveCustomNarratives, resetCustomNarratives } = customNarrativesSlice.actions;
export const customNarratives = customNarrativesSlice.reducer;
export const { saveRoomPreviewKeywords } = roomPreviewKeywordsSlice.actions;
export const roomPreviewKeywords = roomPreviewKeywordsSlice.reducer;
export const {
    saveSort,
    saveFilter,
    resetAll
} = removeContentSlice.actions;
export const removeContentFilter = removeContentSlice.reducer;
export const { saveSelectedCluster, resetSelectedCluster } = selectedClusterSlice.actions;
export const selectedCluster = selectedClusterSlice.reducer;
export const { saveClusterFilters, resetClusterFilters } = clusterFiltersSlice.actions;
export const clusterFilters = clusterFiltersSlice.reducer;
export const { setActiveTab } = activeTabSlice.actions;
export const activeTab = activeTabSlice.reducer;
export const { setActiveListView } = activeAiClusteringTableViewSlice.actions;
export const activeAiClusteringTableView = activeAiClusteringTableViewSlice.reducer;
export const { setNarrativesComparisonList, resetNarrativeList } = narrativeComparisonSlice.actions;
export const narrativeComparison = narrativeComparisonSlice.reducer;
export const { setNarrativesThemeComparisonList, resetNarrativeThemeList } = narrativeThemeComparisonSlice.actions;
export const narrativeThemeComparison = narrativeThemeComparisonSlice.reducer;

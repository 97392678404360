export const sortOrder = [
    'automation_score__',
    'impressions__',
    'likes_count__',
    'pubdatetime__',
    'engagement__',
    'replies_count__',
    'retweets_count__',
    'shares_count__',
    'ctype__keyword__',
    'from__keyword__',
    'locations_detected__keyword__',
    'sentiment__keyword__',
    'threat_object__threat_level__keyword__'
];

export const Order = {
    automation_score__: 'Automation Score',
    impressions__: 'Impressions',
    likes_count__: 'Likes',
    pubdatetime__: 'Time Published',
    engagement__: 'Engagement',
    replies_count__: 'Replies',
    retweets_count__: 'Retweets',
    shares_count__: 'Shares',
    ctype__keyword__: 'Source',
    from__keyword__: 'Author',
    locations_detected__keyword__: 'Location',
    sentiment__keyword__: 'Sentiment',
    threat_object__threat_level__keyword__: 'Threat Level',
};

import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { merge } from 'lodash';
import { SmallPagination } from '../../../../../components/Molecules';
import { CONTENT_ES_SERVICE } from '../../../../../graphql';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { ThreatItem } from './ThreatItem';
import { Loading } from '../../../../../components/Loading';
import { checkFilters } from './index';

export const ThreatsList = ({ state, threat, projectId }) => {
    const { series, order, direction, selection, startDate, endDate } = state;
    const { threat: name } = threat;
    const color = series
        ? series.filter((a) => a?.name === threat.threat)[0]?.color
        : '#333';
    const sort = `${order}${direction}`;
    const [page, setPage] = useState(0);
    const query = checkFilters(threat, 'EsQuery', startDate, endDate, projectId, selection);

    const selectionDate = selection ? selection / 1000 : null;

    console.error(selection, selectionDate);

    const { data, loading } = useQuery(CONTENT_ES_SERVICE, {
        variables: {
            limit: 10,
            skip: page * 10,
            sort,
            query: threat.query_type === 'query' ? merge({
                boosting: {
                    positive: {
                        bool: {
                            filter: [
                                {
                                    match_phrase: {
                                        project_id: {
                                            query: projectId
                                        }
                                    }
                                },
                                ...(selectionDate !== null || startDate !== null ? [{
                                    range: {
                                        pubdatetime: {
                                            gte: selectionDate || startDate,
                                            lte: selectionDate ? (selectionDate + (60 * 60 * 12)) : endDate
                                        },
                                    },
                                }] : [])
                            ]
                        }
                    }
                }
            }, query) : query,
            opts: {
                trackTotalHits: true
            }
        },
    });
    const threats = data?.contentServiceSearch?.hits || [];
    const totalDataCount = data?.contentServiceSearch?.count;
    if (loading) {
        return (
            <Loading relative height={300} />
        );
    }

    return (
        <div key={name} className="content-deck">
            <div className="content-deck-header">
                <div style={{ background: `${color}` }} className="content-deck-header-color" />
                <h3>{capitalizeFirstLetter(name)} ({totalDataCount && totalDataCount})</h3>
            </div>
            <div className="content-wrapper">
                {threats && threats.map((threatItem) => (
                    <div key={threatItem._id}>
                        <ThreatItem threat={threatItem} />
                    </div>
                ))}
            </div>
            <div className="content-wrapper mt-1">
                <div className="custom-narrative-pagination">
                    <SmallPagination total={totalDataCount || 0}
                        itemsPerPage={10}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import { Button, Spinner } from 'reactstrap';
import * as FeatherIcon from 'react-feather';

export const WATCHLIST_BUTTON_TYPES = {
    BUTTON: 'button',
    TEXT_BUTTON: 'text_button',
    TEXT: 'text'
};

type WatchlistButtonProps = {
    isWatching?: boolean,
    addToWatchlist: () => void,
    removeFromWatchlist: () => void,
    isWatchingSpinner: boolean,
    type: string,
    className?: string,
    disabled: boolean
    showText: boolean | string
}

export const WatchlistButton = ({
    isWatching,
    addToWatchlist,
    removeFromWatchlist,
    isWatchingSpinner,
    type,
    className = '',
    disabled,
    showText
}: WatchlistButtonProps) => {
    if (!type) {
        console.error('type is required');
        return null;
    }
    switch (type) {
        case WATCHLIST_BUTTON_TYPES.TEXT_BUTTON:
            return (
                <TextButtonType isWatching={!!isWatching}
                    addToWatchlist={addToWatchlist}
                    removeFromWatchlist={removeFromWatchlist}
                    isWatchingSpinner={isWatchingSpinner}
                    className={className}
                    disabled={disabled}
                    showText={showText}
                />
            );
        case WATCHLIST_BUTTON_TYPES.TEXT:
            return (
                <TextType isWatching={!!isWatching}
                    addToWatchlist={addToWatchlist}
                    removeFromWatchlist={removeFromWatchlist}
                    className={className}
                    disabled={disabled}
                    showText={showText}
                />
            );
        default:
            return (
                <ButtonType isWatching={!!isWatching}
                    addToWatchlist={addToWatchlist}
                    removeFromWatchlist={removeFromWatchlist}
                    isWatchingSpinner={isWatchingSpinner}
                    className={className}
                    disabled={disabled}
                />
            );
    }
};

type ButtonTypeProps = {
    isWatching?: boolean,
    addToWatchlist: () => void,
    removeFromWatchlist: () => void,
    isWatchingSpinner: boolean,
    className: string,
    disabled: boolean
}

const ButtonType = ({
    isWatching,
    addToWatchlist,
    removeFromWatchlist,
    isWatchingSpinner: isLoading,
    className = '',
    disabled
}: ButtonTypeProps) => {
    if (isWatching) {
        return (
            <Button className={className || 'mr-1'}
                onClick={(e) => { e.preventDefault(); removeFromWatchlist(); }}
                data-testid="watchlist-button-type-button"
                disabled={disabled}
            >
                {isLoading ? <Spinner color="primary" type="grow" size="sm" data-testid="spinner" /> : 'Remove from watchlist'}
            </Button>
        );
    }

    return (
        <Button className={className || 'mr-1'}
            onClick={(e) => { e.preventDefault(); addToWatchlist(); }}
            data-testid="watchlist-button-type-button"
            disabled={disabled}
        >
            {isLoading ? <Spinner color="primary" type="grow" size="sm" data-testid="spinner" /> : 'Add to watchlist'}
        </Button>
    );
};

type TextButtonTypeProps = {
    isWatching?: boolean,
    addToWatchlist: () => void,
    removeFromWatchlist: () => void,
    isWatchingSpinner: boolean,
    className: string,
    disabled: boolean
    showText: boolean | string
}

const TextButtonType = ({
    isWatching,
    addToWatchlist,
    removeFromWatchlist,
    isWatchingSpinner,
    className,
    disabled,
    showText = true
}: TextButtonTypeProps) => {
    const handleRemoveFromWatchlist = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) removeFromWatchlist();
    };

    const handleAddToWatchlist = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!disabled) addToWatchlist();
    };

    return (
        <div className={className || 'd-inline-block'} data-testid="watchlist-text-button-type-button">
            {isWatching ? (
                <span onClick={handleRemoveFromWatchlist} className="text-primary cursor-pointer" data-testid="watchlist-button">
                    <FeatherIcon.Star size={24} className="fill-primary" />
                    {showText && (
                        <span className="pl-1">Remove from watchlist</span>
                    )}
                </span>
            ) : (
                <span onClick={handleAddToWatchlist} className="text-primary cursor-pointer" data-testid="watchlist-button">
                    <FeatherIcon.Star size={24} />
                    {showText && (
                        <span className="pl-1">Add to watchlist</span>
                    )}
                </span>
            )}
            {isWatchingSpinner && (
                <Spinner color="primary" type="grow" size="sm" className="ml-1" data-testid="spinner" />
            )}
        </div>
    );
};

type TextTypeProps = {
    isWatching?: boolean,
    addToWatchlist: () => void,
    removeFromWatchlist: () => void,
    className: string,
    disabled: boolean
    showText?: string | boolean
}

const TextType = ({
    isWatching,
    addToWatchlist,
    removeFromWatchlist,
    className = '',
    showText,
    disabled
}: TextTypeProps) => {
    const handleRemoveFromWatchlist = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        if (!disabled) removeFromWatchlist();
    };

    const handleAddToWatchlist = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        if (!disabled) addToWatchlist();
    };

    return (
        <div data-testid="watchlist-text-type-button"
            className={className}
            onClick={isWatching ? handleRemoveFromWatchlist : handleAddToWatchlist}
        >
            {isWatching ? (
                <span data-testid="watchlist-button">
                    {showText !== 'AI Narratives' ? 'Remove from watchlist' : 'Remove from watchlist'}
                </span>
            ) : (
                <span data-testid="watchlist-button">
                    {showText !== 'AI Narratives' ? 'Add to watchlist' : 'Add to watchlist'}
                </span>
            )}
        </div>
    );
};

import { useSelector } from 'react-redux';
import { getRoom } from '../../utils/variables';

export const useFilterQuery = (threat) => {
    const filters = useSelector((state) => state.filters.filters);
    const minShouldMatch = [filters.threatTypes.length > 0, filters.origin.length > 0].filter(a => a);
    const room = getRoom();
    return {
        sort: filters.sort,
        query: {
            bool: {
                ...(filters.query !== '' && {
                    must: [
                        {
                            query_string: {
                                query: filters.query,
                                fields: ['text', 'from', 'mentions'],
                            },
                        }
                    ],
                }),
                should: [
                    ...(filters.threatTypes.length > 0 ? [{
                        terms: {
                            threat_object__threat_labels__keyword: threatTypes.filter((a) => filters.threatTypes.indexOf(a) === -1),
                        },
                    }] : []),
                    ...(filters.origin.length > 0 ? [{
                        terms: {
                            locations_detected__keyword: filters.origin,
                        },
                    }] : [])
                ],
                minimum_should_match: minShouldMatch.length.toString(),
                must_not: [
                    {
                        terms: {
                            ctype__keyword: filters.sources,
                        },
                    },
                    {
                        terms: {
                            sentiment__keyword: filters.sentiment,
                        },
                    }
                ],
                filter: [
                    ...(threat ? [{
                        match_phrase: {
                            threat_object__threat_level: {
                                query: 'high',
                            }
                        },
                    }] : []),
                    ...(Object.keys(filters.dateRange).length > 0 ? [{
                        range: {
                            pubdatetime: {
                                gte: filters.dateRange.startDate,
                                lte: filters.dateRange.endDate
                            }
                        }
                    }] : []),
                    ...(room ? [{
                        match_phrase: {
                            project_id: {
                                query: room.project_id,
                            },
                        },
                    }] : []),
                    ...(filters.convertedBooleanQuery?.length > 0
                        ? [
                            {
                                query_string: {
                                    query: filters.convertedBooleanQuery.boosting?.positive,
                                    fields: ['body', 'text', 'url', 'title'],
                                    lenient: true
                                },
                            }
                        ]
                        : [])
                ],
            },
        }
    };
};

const threatTypes = [
    'fn-type',
    'deep-toxic-classifier',
    'src-cred',
    'automation_score',
    'custom',
    'account_based'
];

import React from 'react';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { checkGrammer } from '../../../../../utils/text/checkGrammer';

export enum WidgetName {
    Engagement = 'Engagement',
    Narratives = 'Narratives',
    Content = 'Content',
    Duplicates = 'Duplicates',
    Sentiment = 'Sentiment',
    OriginalPoster = 'Original poster',
    NarrativeTheme = 'Narrative theme',
    Actors = 'Actors (No. of content)',
    Keywords = 'Keywords (No. of content)',
    Sources = 'Sources (No. of content)',
    LocationMentions = 'Location mentions (No. of content)',
    Timeline = 'Timeline',
    ContentSentiment = 'Sentiment ',
    KeywordsMentions= 'Keywords mentions',
    Labels = 'Labels'
}

type WidgetTextTooltipProps = {
    widgetName: WidgetName | string
    clusterId?: string
    noMargin?: boolean
    className?: string
    classes?: string
    isMultiple?: boolean
}

export const WidgetTextTooltip = ({ widgetName, clusterId, noMargin = false, className = '', classes = '',
    isMultiple = false }: WidgetTextTooltipProps) => {
    let tooltipText = '';
    const id = ['Original poster', 'Narrative theme'].includes(widgetName)
        ? `tooltip-${widgetName.replace(/ /g, '_')}-${clusterId}`
        : `tooltip-${widgetName}`;
    switch (widgetName) {
        case WidgetName.Engagement:
            tooltipText = 'The level of engagement and response to a piece of content e.g. likes, shares, comments.';
            break;
        case WidgetName.Narratives:
            tooltipText = 'The number of narratives within this narrative theme.';
            break;
        case WidgetName.Content:
            tooltipText = 'The number of pieces of content in total. This number is rounded to the nearest 1000 in this summary.';
            break;
        case WidgetName.Duplicates:
            tooltipText = `The number of duplicates detected. These are nested into one duplicate so 
            each duplicate may contain multiple pieces of content.`;
            break;
        case WidgetName.Sentiment:
            tooltipText = `We determine the aggregated sentiment of a narrative by 
            assessing each piece of content within it for semantic signals to identify if it is overall positive, negative or neutral.`;
            break;
        case WidgetName.OriginalPoster:
            tooltipText = 'The Actor who posted the first piece of content in the cluster';
            break;
        case WidgetName.NarrativeTheme:
            tooltipText = 'Narratives grouped into themes based on similarities and commonalities.';
            break;
        case WidgetName.Actors:
            tooltipText = 'The top actors that contribute and a count of their contributions';
            break;
        case WidgetName.Sources:
            tooltipText = 'A breakdown of the sources for the content in a narrative eg. YouTube, Telegram';
            break;
        case WidgetName.Keywords:
            tooltipText = 'Key terms that appear and a count of their recurrences';
            break;
        case WidgetName.LocationMentions:
            tooltipText = 'The amount of times a location is mentioned in a narrative';
            break;
        case WidgetName.Timeline:
            tooltipText = `Content engagement refers to the level of engagement and response to a piece of content,
                E.g. likes, shares, comments`;
            break;
        case WidgetName.ContentSentiment:
            tooltipText = `Sentiment is how Logically Intelligence perceives this content to be towards the subject that 
            it’s discussing.`;
            break;
        case WidgetName.KeywordsMentions:
            tooltipText = 'Keywords that are mentioned within the content.';
            break;
        case WidgetName.Labels:
            tooltipText = 'Labels allows you to manually label documents within this platform for easier filtering.';
    }

    return (
        <TooltipWrapper tooltipText={tooltipText} id={id} placement="top-start" className={className}>
            <p className={`mb-${noMargin ? '0' : '1'} ${classes} widget-text-style`}>{checkGrammer(widgetName, isMultiple)}</p>
        </TooltipWrapper>
    );
};

type CheckForNegativeQueryProps = {
    included: string[]
    excluded: string[]
    boolean: string,
    userQuery: string
}

export const checkForNegativeQuery = ({ included, excluded, boolean, userQuery }: CheckForNegativeQueryProps) => {
    const convertKeywordsToQuery = () => {
        const includedQuery = included.length > 0 ? included.join(' OR ') : '';
        const excludedQuery = excluded.length > 0 ? excluded.join(' OR ') : '';
        return `(${includedQuery})${excluded.length ? ` NOT (${excludedQuery})` : ''}`;
    };
    const checkNotOperator = (inputString: string) => {
        const regex = /^\s*NOT(\s|$)/;
        return regex.test(inputString);
    };
    if (boolean) {
        if (checkNotOperator(userQuery)) {
            return `(${boolean}) ${userQuery}`;
        }
        return userQuery;
    }
    return convertKeywordsToQuery();
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import moment from 'moment';
import { CustomInput, DropdownItem } from 'reactstrap';
import { Options, SeriesOptionsType } from 'highcharts';
import { IconButton } from '../../../../components/Atoms';
import { Cluster, SubCluster } from '../types';
import { LoadingState } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { ActionsDropdown } from '../../../../components/Atoms/ActionsDropdown';
import { useChartExport } from '../../../../components/Highcharts/useChartExport';
import { ResponsiveChart, ResponsiveChartRef } from '../../../../components/Highcharts/ResponsiveChart';
import { color } from '../../../../utils/getColors';

type TimelineChartProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    data: SubCluster[] | Cluster[];
    colors: string[];
    isParent: boolean;
    loading: boolean;
};

export const TimelineChart = ({ isCollapsed, setCollapsable, data, colors, isParent, loading }: TimelineChartProps) => {
    const chartRef = useRef<ResponsiveChartRef>(null);

    const exportChart = useChartExport({
        chartRef,
        fileName: 'volume-over-time'
    });
    const [type, setType] = useState<'volume' | 'engagement'>('volume');
    const [selectedSeries, setSelectedSeries] = useState<{ id: string[]; colors: { [key: string]: string } }>({
        id: [],
        colors: {}
    });

    const handleSeriesToggle = (id: string) => {
        setSelectedSeries(prevSelected => {
            const isAlreadySelected = prevSelected.id.includes(id);
            return {
                id: isAlreadySelected ? prevSelected.id.filter(item => item !== id) : [...prevSelected.id, id],
                colors: prevSelected.colors
            };
        });
    };
    const seriesData = useMemo(
        () =>
            data
                ?.filter(innerClusterData => selectedSeries?.id.includes(innerClusterData?.id))
                .map(innerClusterData => {
                    const singleCluster = type === 'volume' ? innerClusterData?.volumeOverTime || []
                        : innerClusterData?.engagementOverTime || [];
                    const newClusterData = singleCluster?.map(item => ({
                        x: type === 'volume' ? moment(isParent ? item.time : item.date, 'DD-MM-YYYY').valueOf()
                            : moment(item.time, 'DD-MM-YYYY').valueOf(),
                        y: item.count
                    }))
                        .sort((a, b) => a.x - b.x); // Sort by timestamp
                    return {
                        data: newClusterData,
                        name: isParent
                            ? (innerClusterData as SubCluster)?.subClusterTitle
                            : (innerClusterData as Cluster)?.title,
                        color: selectedSeries.colors[innerClusterData.id], // Use color from selectedSeries
                    };
                }),
        [data, selectedSeries, type, isParent]
    );
    const convertedSeriesData: SeriesOptionsType[] = seriesData.map(series => ({
        ...series,
        type: 'spline',
        marker: {
            enabled: false
        }
    }));
    const resetZoomButtonRef = useRef<Highcharts.SVGElement | null>(null);
    const options = {
        title: {
            text: '',
            align: 'left',
            style: {
                color: color.darkblue['700'],
                fontFamily: '"Nunito", sans-serif',
                fontSize: '18px',
                fontWeight: 'bold'
            }
        },
        exporting: {
            enabled: false,
        },
        chart: {
            height: 350,
            zoomType: 'x',
            plotBorderWidth: 1,
            type: 'spline',
            style: {
                fontFamily: 'serif'
            },
            events: {
                selection() {
                    const chart = this as Highcharts.Chart;
                    const { renderer, xAxis } = chart;

                    if (resetZoomButtonRef.current) {
                        resetZoomButtonRef.current.destroy();
                    }

                    resetZoomButtonRef.current = renderer.button('Reset zoom', 0, 0, () => {
                        xAxis[0].setExtremes();
                        if (resetZoomButtonRef.current) {
                            resetZoomButtonRef.current.destroy();
                            resetZoomButtonRef.current = null;
                        }
                    }, {
                        zIndex: 7,
                        fill: '#fff',
                        stroke: '#006FF9',
                        style: {
                            color: '#006FF9'
                        }
                    }, {
                        fill: '#006FF9',
                        style: {
                            color: '#fff'
                        }
                    }).attr({
                        id: 'resetZoom',
                        align: 'right',
                        title: 'Reset zoom level'
                    }).add().align({
                        align: 'right',
                        x: -20,
                        y: 20
                    }, false);
                }
            }
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineColor: color.grey[300],
            gridLineWidth: 1,
            labels: {
                style: {
                    color: color.grey[400]
                }
            },
            lineColor: color.grey[300],
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            tickmarkPlacement: 'on',
            labels: {
                style: {
                    color: color.grey[400]
                }
            },
            tickWidth: 0,
            lineColor: color.grey[300],
        },
        series: convertedSeriesData,
        tooltip: {
            shared: true,
            split: false,
            backgroundColor: '#fff',
            borderWidth: 0,
            padding: 0,
            headerFormat: `<div class="d-flex justify-content-between p-1">
                <span class="mr-4">Date</span>
                <span>{point.key}</span>
            </div><hr style="margin: 2px 0;" />`,
            pointFormatter() {
                const { series, y } = this;
                return `<div class="d-flex justify-content-between p-1">
                    <div class="d-flex align-items-center">
                        <div class="mr-1" style="background-color: ${(series as any).color};
                        height: 12px; width: 12px; border-radius: 2px;"></div>
                        Narrative ${series.index + 1}
                    </div>
                    <span>${y}</span>
                </div><hr class="m-0" />`;
            },
            xDateFormat: '%d/%m/%Y %l:%M %p',
            style: {
                color: color.darkblue[700],
                fontFamily: '"Nunito", sans-serif',
                fontSize: '14px'
            },
            useHTML: true
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        colors: [color.blue[500]],
    } as Options;

    useEffect(() => {
        if (data) {
            setSelectedSeries({
                id: data.map(innerClusterData => innerClusterData.id),
                colors: data.reduce((acc, innerClusterData, index) => {
                    acc[innerClusterData.id] = colors[index];
                    return acc;
                }, {} as { [key: string]: string })
            });
        }
    }, [data, colors]);
    return (
        <div className="mb-4">
            <IconButton
                className="icon-button text-decoration-none"
                icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                onClick={setCollapsable}
                text={<WidgetTextTooltip widgetName={WidgetName.Timeline} noMargin className="ml-2" />}
                dataTestid="timeline"
            />

            {isCollapsed && (
                <div className="bg-white p-2 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex px-1">
                            <input
                                type="radio"
                                id="volume"
                                name="perf-graph"
                                value="volume"
                                checked={type === 'volume'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setType('volume');
                                    }
                                }}
                            />
                            <span className="pt-1 text-primary">Volume</span>
                            <input
                                className="ml-2"
                                type="radio"
                                id="engagement"
                                name="perf-graph"
                                value="engagement"
                                checked={type === 'engagement'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setType('engagement');
                                    }
                                }}
                            />
                            <span className="pt-1 text-primary">Engagement</span>
                        </div>
                        <div>
                            <ActionsDropdown className="ml-auto">
                                <DropdownItem onClick={() => exportChart({ chartOptions: { chart: { width: 1100 } } })}>
                                    Export as JPEG
                                </DropdownItem>
                            </ActionsDropdown>
                        </div>
                    </div>
                    <div className="mt-2">

                        <div>
                            {loading ? <LoadingState items={[1]} /> : (
                                <>
                                    <hr className="mt-0" />
                                    <div className="p-2">
                                        <p className="mb-2">To zoom in on a specific period or date, you can
                                            click and drag horizontally within the graph
                                        </p>
                                        <ResponsiveChart options={options} ref={chartRef} />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="grid-container-4 gap-2">
                            {data?.map((item, index) =>
                                (
                                    <div key={item.id} className="card p-2 border shadow-none d-flex flex-row align-items-center">
                                        <CustomInput
                                            id={item.id}
                                            type="checkbox"
                                            color={colors[index % colors.length].replaceAll('#', '-')}
                                            className={`checkbox-color${colors[index % colors.length].replaceAll('#', '-')}`}
                                            checked={selectedSeries.id.includes(item.id)}
                                            onChange={() => handleSeriesToggle(item.id)}
                                        />
                                        <p className="m-0 p-0">
                                            {`Narrative ${index + 1}`}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

import React from 'react';
import { Slide, toast } from 'react-toastify';
import { AlertTriangle, CheckCircle, Info } from 'react-feather';
import { color } from './getColors';

export const getToast = () => {
    const defaultOptions = {
        autoClose: 5000,
        transition: Slide,
    };
    const toaster = (text, options) => toast(text, { options, ...defaultOptions });
    toaster.success = (text, options) => toast.success(text, {
        ...defaultOptions,
        ...options,
        icon: <CheckCircle color={color.green[200]} />
    });
    toaster.error = (text, options) => toast.error(text, {
        ...defaultOptions,
        ...options,
        icon: <AlertTriangle color={color.red[200]} />,
        style: {
            backgroundColor: color.red[100]
        }
    });
    toaster.info = (text, options) => toast.info(text, {
        ...defaultOptions,
        ...options,
        icon: <Info color={color.yellow[300]} />,
        style: {
            backgroundColor: color.yellow[100]
        }
    });
    return toaster;
};

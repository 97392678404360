import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MoreVertical } from 'react-feather';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useMarkAsRead } from '../../../services/Alerts/useMarkAsRead';
import { NotificationSummary } from './NotificationSummary';
import { saveFilters, saveSelectedFilters } from '../../../pages/Room/store';
import { getCtype } from '../../../utils/text';
import { useArchiveAlert } from '../../../services/Alerts/useArchiveAlert';
import { Alert } from '../../../generated/graphql';

type AlertSituation = {
    id: string,
    name: string
   }

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean,
    archived?: boolean
};

type NotificationProps = {
    notification: NotificationDefaultProps
    showUnread?: boolean
    archive?: boolean
}

export const NotificationCard = ({ notification, showUnread, archive }: NotificationProps) => {
    const { markAsRead, markAsUnread } = useMarkAsRead({ alertIds: [notification.id], showUnread, markUnread: notification.read });
    const { archiveAlert } = useArchiveAlert({
        alertIds: [notification.id]
    });
    const threatResolvers = ['ATTACK_ON_COMMENTER', 'ATTACK_ON_AUTHOR',
        'IDENTITY_ATTACK', 'INSULT', 'INFLAMMATORY', 'TOXICITY', 'SEVERE_TOXICITY', 'THREAT'];

    const dispatch = useDispatch();
    const date = notification.date / 1000;
    const endDate = date + (7 * 24 * 60 * 60);
    const startDate = date - (7 * 24 * 60 * 60);

    const setFilters = () => {
        if (notification.category === 'ctypes') {
            const filters = {
                contentType: [notification.subcategory],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: notification.situationRoom.id,
                sourcesWithDomains: [notification.subcategory].map((
                    item
                ) => ({ key: item, label: getCtype(item) })),
                sources: [notification.subcategory],
                sentiment: [],
                threatTypes: []
            };
            dispatch(saveFilters(filters));
            dispatch(saveSelectedFilters(filters));
        }
        else if (notification.category === 'sentiments') {
            const sentimentFilters = {
                sentiment: [notification.subcategory],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: notification.situationRoom.id,
                contentType: []

            };
            dispatch(saveFilters(sentimentFilters));
            dispatch(saveSelectedFilters(sentimentFilters));
        }
        else if (notification.category === 'threat_types') {
            const threatFilters = {
                threatTypes: notification.subcategory === 'deep-toxic-classifier'
                    ? [...threatResolvers] : [notification.subcategory],
                isThreat: true,
                dateRange: {
                    startDate,
                    endDate
                },
                contentType: [],
                sentiment: [],
                roomId: notification.situationRoom.id
            };
            dispatch(saveFilters(threatFilters));
            dispatch(saveSelectedFilters(threatFilters));
        }
        else if (notification.category === 'overall') {
            const overAllFilters = {
                contentType: [],
                dateRange: {
                    startDate,
                    endDate
                },
                roomId: notification.situationRoom.id,
                sourcesWithDomains: [],
                sources: [],
                sentiment: [],
                threatTypes: []
            };
            dispatch(saveFilters(overAllFilters));
            dispatch(saveSelectedFilters(overAllFilters));
        }
    };

    return (
        <div className="dropdown-item p-2 mb-1 d-flex noti-page rounded"
            data-testid={`notification-item-${notification.projectId}`}
        >
            <div className="mr-2">
                <p className="noti-avatar">LI</p>
            </div>
            <div>
                <p className="text-wrap">
                    <NotificationSummary
                        category={notification.category}
                        subcategory={notification.subcategory}
                        description={notification.description}
                    />
                </p>
                <Link
                    to={getNotificationUrl(notification)}
                    onClick={setFilters}
                >
                    <p className="text-primary d-inline font-size-14">
                        {notification.situationRoom.name}
                    </p>&nbsp;
                </Link>
                <p className="d-inline font-size-14">
                    | {moment(notification.date).format('DD-MM-YYYY')}
                </p>
                {!notification.read
                        && <span className="unread ml-1" data-testid="unread-noti" /> }
            </div>
            {!archive
                && (
                    <UncontrolledDropdown direction="left" className="mw-0 mr-0 menu-hide ml-auto">
                        <DropdownToggle tag="div">
                            <MoreVertical size={24} role="button" className="text-primary d-block" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem className="mw-0 px-2 py-1 rounded"
                                onClick={() => {
                                    if (!notification.read) {
                                        markAsRead();
                                    }
                                    else {
                                        markAsUnread();
                                    }
                                }}
                            >
                                {!notification.read ? 'Mark as read' : 'Mark as Unread'}
                            </DropdownItem>
                            <DropdownItem onClick={() => archiveAlert()} className="mw-0 px-2 py-1 rounded">
                                Archive
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
        </div>
    );
};

export const getNotificationUrl = (notification: Alert) => {
    const page = (notification.category === 'threat_types' || notification.category === 'threats') ? 'threats' : 'overview';
    const URI = `/situation-rooms/${notification.situationRoom?.id}/${page}`;
    const date = notification.date / 1000;
    const sevenDays = 7 * 24 * 60 * 60;
    const startDate = date - sevenDays;
    const endDate = date + sevenDays;

    return `${URI}?daterange=${startDate},${endDate}&${notification.category}=${notification.subcategory}`;
};

import { useMutation } from '@apollo/client';
import { gql } from '../../generated';

export const useFileUploadSignedURL = () => {
    const [getSignedUrl, { data, loading }] = useMutation(GET_FILE_UPLOAD_SIGNED_URL);

    return {
        loading,
        signedUrl: data?.getFileUploadSignedURL,
        getSignedUrl: ({ filename, contentType, feature }: { filename: string; contentType: string; feature: string; }) => getSignedUrl({
            variables: {
                filename,
                contentType,
                feature
            }
        })
    };
};

const GET_FILE_UPLOAD_SIGNED_URL = gql(
    `mutation GetFileUploadSignedURL($filename: String!, $contentType: String!, $feature: String!) {
        getFileUploadSignedURL(filename: $filename, contentType: $contentType, feature: $feature)
    }`
);

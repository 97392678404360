import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Loading } from '../../../../components/Loading';
import { resetSelectedCluster, setActiveListView } from '../../store';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { ClustersTable } from './ClustersTable';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../graphql';
import { Cluster } from '../types';
import { useGlobalFiltersInClusters } from '../../../../services/Clusters/GlobalFIltersInClusters';
import { EmptyState } from '../../../../components/EmptyState';
import { NoSearchResults } from '../../../../assets/svg/noSearchResults';
import { RootState } from '../../../../store';

export const VIEW_MODES = {
    THEMES: 'themes',
    NARRATIVES: 'narratives'
};

export type ListViewRef = {
    handleSearch: () => void;
}

type ListViewProps = {
    sort: { field: string, order: string }
}

const itemsPerPage = 10;

export const ListView = forwardRef(({ sort }: ListViewProps, ref) => {
    const { selectedCluster } = useSelector((state: RootState) => state.selectedCluster);
    const dispatch = useDispatch();
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const paramClustersPage = parseInt(getParam('clusters-page') || '1', 10);
    const isSubClustersPageParam = getParam('sub-clusters-page') !== null;
    const [clustersOffset, setClustersOffset] = useState((paramClustersPage - 1) * itemsPerPage);

    useEffect(() => {
        dispatch(setActiveListView({ viewMode: isSubClustersPageParam ? VIEW_MODES.NARRATIVES : VIEW_MODES.THEMES }));
    }, [dispatch, isSubClustersPageParam]);

    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: itemsPerPage,
            skip: clustersOffset,
            ...filters,
            sort
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: () => {
            if (selectedCluster && !clustersData?.getClusters?.clusters?.find((cluster: Cluster) => cluster.id === selectedCluster)) {
                setSelectedCluster('');
                dispatch(resetSelectedCluster());
            }
        }
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];

    const setSelectedCluster = (id: string) => {
        unsetParam('tab');
        if (!id) {
            return unsetParam('narrative-theme');
        }
        setParam('narrative-theme', id);
    };

    const handleSearch = () => {
        if (selectedCluster) {
            setSelectedCluster('');
            dispatch(resetSelectedCluster());
        }
        setParam('page', '1');
        setClustersOffset(0);
    };

    const handleClustersOffsetChange = (e: number) => {
        setClustersOffset(e);
        unsetParam('sub-clusters-page');
        setParam('clusters-page', ((e / itemsPerPage) + 1).toString());
    };
    useImperativeHandle(ref, () => ({
        handleSearch
    }));

    if (clusterLoading && !clusters?.length) {
        return <Loading gif relative height={400} />;
    }

    if (clusters?.length < 1) {
        return (
            <EmptyState title="No data found."
                summary="Please change the search string and try again."
                svgIcon={<NoSearchResults />}
            />
        );
    }

    return (
        <div className="ai-narratives-list-view">
            <ClustersTable clusters={clusters}
                total={getClustersData.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={handleClustersOffsetChange}
                itemsPerPage={itemsPerPage}
            />
        </div>
    );
});

import React, { useEffect, useMemo, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Table,
    Alert
} from 'reactstrap';
import { AlertCircle } from 'react-feather';
import { useQuery } from '@apollo/client';
import { GET_INVESTIGATIONS, GET_INVESTIGATION_TOKENS } from '../../../graphql';
import { RequestInvestigation } from '../../../components/RequestInvestigation';
import { setParam, getParam, HandleLocationChange } from '../../../utils/urlParams';
import noDataAvailable from '../../../assets/images/NoDataAvailable.png';
import { useModal } from '../../../context/modal/ModalComponent';
import { InvestigationItem } from './InvestigationsItem';
import { RequestInvestigationsSection } from './RequestInvestigationsSection';
import { useUser } from '../../../context/user/UserContext';
import TicketsIcon from '../../../assets/images/investigations/tickets.svg';
import { TokensRequestModal } from './TokensRequestModal';
import { Loading } from '../../../components/Loading';
import { useGetInstance } from '../../../services/Instance/getInstance';
import { color } from '../../../utils/getColors';

export const Investigations = ({ bread, project, instance, enabled, accountManager }) => {
    const { isAdmin } = useUser();
    const { setModal, closeModal } = useModal();
    const pageKey = 'q';
    const pageOptions = useMemo(() => ({ all: 0, 'in-progress': 1, completed: 2 }), []);
    const defaultPageParam = getParam(pageKey);
    const [pageParam, setPageParam] = useState(defaultPageParam);
    const [selectedTab, setSelectedTab] = useState(pageParam ? pageOptions[pageParam] : 0);

    const { data, refetch, loading } = useQuery(GET_INVESTIGATIONS, {
        variables: { situationRoom: project },
    });
    const { instance: instanceData, refetchInstance } = useGetInstance({ instanceId: instance });
    const { data: requestedTokens } = useQuery(GET_INVESTIGATION_TOKENS, {
        variables: { instance },
        fetchPolicy: 'no-cache'
    });
    const availableTokens = requestedTokens?.getInvestigationTokens[0].tokens;
    const totalTokens = requestedTokens?.getInvestigationTokens[0].totalTokens;
    const investigations = data ? data?.investigations : [];
    const onSetPageParam = (value) => {
        setPageParam(value);
        setParam(pageKey, value);
    };
    useEffect(() => {
        if (pageOptions[defaultPageParam] !== undefined) {
            setSelectedTab(pageOptions[defaultPageParam]);
        }
    }, [defaultPageParam, pageOptions, selectedTab]);

    const investigationLength = () => {
        if (selectedTab === 0) {
            return investigations.length;
        }
        if (selectedTab === 1) {
            let progress = 0;
            for (let index = 0; index < investigations.length; index++) {
                const element = investigations[index];
                if (element.status === 'In Progress') {
                    progress++;
                }
            }
            return progress;
        }
        if (selectedTab === 2) {
            let completed = 0;
            for (let index = 0; index < investigations.length; index++) {
                const element = investigations[index];
                if (element.status === 'Completed') {
                    completed++;
                }
            }
            return completed;
        }
    };

    const toggleRequestTokensModal = () => {
        setModal({
            component: (
                <TokensRequestModal requestedTokens={instanceData ? instanceData?.instance?.plan?.requestedTokens : 0}
                    instance={instance}
                    plan={instanceData ? removeTypeName(instanceData?.instance?.plan) : {}}
                    clientName={instanceData?.instance?.clientName}
                />
            )
        });
    };

    const setRequestInvestigationModal = () => {
        if (availableTokens === 0) {
            setModal({
                component: (
                    <div>
                        <img src={TicketsIcon} alt="no-tokens" />
                        <h2>You don&apos;t have enough tokens</h2>
                        <hr />
                        <p>Request for tokens and you can request an investigation when it is approved.</p>
                        <hr />
                        <Button onClick={() => { closeModal(); toggleRequestTokensModal(); }}>Request for tokens</Button>
                    </div>
                )
            });
        }
        else {
            setModal({
                modal: (
                    <RequestInvestigation project={project}
                        instance={instance}
                        onClose={() => {
                            closeModal();
                            refetch();
                            refetchInstance();
                        }}
                    />
                )
            });
        }
    };

    if (loading) {
        return <Loading gif relative height={600} />;
    }

    if (!enabled) {
        return (
            <div>
                <Row className="mt-5 align-items-center justify-content-center">
                    <Col xs={6} md={4} lg={3}>
                        <img src={noDataAvailable}
                            alt="investigations-animated"
                            className="unknown-location"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <h2>Logically Investigations</h2>
                        <h1 data-testid="unlock-access"
                            className="text-primary my-4"
                        >Unlock access to Logically&apos;s Investigations team to escalate threats
                        </h1>
                        <ul>
                            <li className="font-size-16 mb-2">In-depth analysis of threats detected in or outside of the platform</li>
                            <li className="font-size-16 mb-2">
                                Specialist team dedicated to understanding the impact of detected threats using open-source data
                            </li>
                            <li className="font-size-16 mb-2">Track progress and download your report</li>
                            <li className="font-size-16 mb-2">
                                Redeem a token per report to track usage across your team and situation rooms
                            </li>
                        </ul>
                        <a href={`mailto:${accountManager}?subject=Request access to Logically Investigations integration`}>
                            <Button color="primary" className="font-size-16 mt-3">Contact your account owner for access</Button>
                        </a>
                    </Col>
                </Row>
            </div>
        );
    }

    const sortByCreated = (a, b) => {
        if (a.createdAt - b.createdAt) {
            return -1;
        }

        return 0;
    };

    return (
        <div className="page-padding">
            {bread}
            <HandleLocationChange page={selectedTab}
                setPage={setSelectedTab}
                pageOptions={pageOptions}
                pageKey={pageKey}
            />
            <div className="d-flex justify-content-between">
                <h2 className="mb-4 mt-2">Investigations</h2>
                <div className="d-flex mb-1 align-items-center">
                    <strong>{availableTokens}/{totalTokens} tokens available</strong>
                    {isAdmin && (
                        <Button color="link"
                            className="pr-0 w-fit-content"
                            type="button"
                            onClick={() => toggleRequestTokensModal()}
                        >
                            Add more
                        </Button>
                    )}
                </div>
            </div>
            <Row>
                <Col xs={12}>
                    <div className="d-flex justify-content-between">
                        <div className="multi-tabs">
                            <div className={`mr-4 ${selectedTab === 0 ? 'selected-tab ' : ''}`}
                                onClick={() => onSetPageParam('all')}
                            >
                                <h4>All</h4>
                            </div>
                            <div className={`mr-4 ${selectedTab === 1 ? 'selected-tab ' : ''}`}
                                onClick={() => onSetPageParam('in-progress')}
                            >
                                <h4>In progress</h4>
                            </div>
                            <div className={`mr-4 ${selectedTab === 2 ? 'selected-tab ' : ''}`}
                                onClick={() => onSetPageParam('completed')}
                            >
                                <h4>Completed</h4>
                            </div>
                        </div>
                        {(investigations.length !== 0) && (
                            <Button className="mb-4"
                                color="secondary"
                                type="button"
                                disabled={!isAdmin && availableTokens === 0}
                                onClick={setRequestInvestigationModal}
                            >
                                Request investigation
                            </Button>
                        )}
                    </div>
                    {(!isAdmin && availableTokens === 0) && (
                        <Alert className="mb-4" color="warning">
                            <span><AlertCircle size="20" color={color.yellow[300]} /></span>
                            <p className="d-inline ml-1">There is no token available for
                                you to request an investigation. Get in touch with your admin.
                            </p>
                        </Alert>
                    )}

                    {(
                        !loading
                        && investigations.length === 0)
                    && (
                        <RequestInvestigationsSection tokens={availableTokens}
                            setOpen={setRequestInvestigationModal}
                            title="No Investigations requested yet."
                            isAdmin={isAdmin}
                        />
                    )}
                    {(
                        !loading
                        && investigations.length !== 0
                        && (selectedTab !== 0)
                        && investigations.filter(investigation =>
                            (investigation.status === (selectedTab === 1 ? 'In Progress' : 'Completed'))).length === 0)
                    && (
                        <RequestInvestigationsSection setOpen={setRequestInvestigationModal}
                            title="No Investigations requested yet"
                            subtitle="There aren't any active investigations yet,"
                            subtitle2="but you can request one based on any "
                            subtitle3="of your countermeasures."
                            isAdmin={isAdmin}
                            tokens={availableTokens}
                        />
                    )}
                    {investigations.length > 0 && investigationLength() > 0 && (
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th><p className="pl-2">Investigation title ({investigationLength()})</p></th>
                                    <th>Contact email</th>
                                    <th>Requested date</th>
                                    <th style={{ width: 150 }}>Status</th>
                                    <th>Attachments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {investigations.filter(investigation => {
                                    if (selectedTab === 1 && investigation.status === 'Completed') return false;
                                    return !(selectedTab === 2 && investigation.status === 'In Progress');
                                })
                                    .sort(sortByCreated)
                                    .map(investigation => (
                                        <React.Fragment key={investigation.id}>
                                            <InvestigationItem data={investigation}
                                                key={investigation.id}
                                                onSetPageParam={onSetPageParam}
                                            />
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        </Table>
                    )}
                </Col>

            </Row>
        </div>
    );
};

const removeTypeName = (data) => {
    if (!data) { return {}; }
    const ret = {};
    const keys = Object.keys(data).filter(k => k !== '__typename');
    for (let i = 0; i < keys.length; i++) {
        if (typeof data[keys[i]] === 'object' && data[keys[i]]) {
            ret[keys[i]] = removeTypeName(data[keys[i]]);
        } else {
            ret[keys[i]] = data[keys[i]];
        }
    }
    return ret;
};

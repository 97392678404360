import React from 'react';
import { Col } from 'reactstrap';
import _ from 'lodash';
import { getRoom } from '../../../../utils/variables';
import { allTypes } from '../../../../utils/lib/search';
import { ContentTableContentType } from '..';
import { ThreatSignalTooltip } from './threatSignalTooltip';

type ThreatSignalsProps = {
    content: Pick<ContentTableContentType, 'threatObject' >,
    handleSignalClick: () => void
}

export const ThreatSignalLabels = ({ content, handleSignalClick }: ThreatSignalsProps) => {
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const { threatObject } = content;

    const {
        threatResolvers = null,
        removedThreatLabels = null,
        customThreatLabels = null
    } = threatObject ?? {};

    const displayList = [] as {signalName: string, confidence: number, isResolver: boolean}[];
    allTypes.forEach(type => {
        if (disabledThreatTypes.includes(type.key)) {
            return false;
        }
        const key = type.isResolver ? 'resolver' : 'type';
        threatResolvers?.forEach((c: ContentTableContentType['threatObject']['threatResolvers']) => {
            if ((c[key] === type.key) && (removedThreatLabels ? !removedThreatLabels.includes(type.key) : true)) {
                const item = { signalName: '', confidence: 0, isResolver: false };
                item.signalName = type.name;
                item.confidence = c.confidence;
                item.isResolver = type.isResolver || false;
                displayList.push(item);
            }
        });
        if (customThreatLabels && (customThreatLabels.map(a => a.signalKey)).includes(type.key)) {
            const item = { signalName: '', confidence: 0, isResolver: false };
            item.signalName = type.name;
            item.confidence = 0;
            item.isResolver = type.isResolver || false;
            displayList.push(item);
        }
    });
    if (displayList.length === 0) {
        return null;
    }
    const sortSignals = () => {
        const finalDisplayList = _.uniqBy(displayList, 'signalName');
        if (finalDisplayList.length > 1) {
            return finalDisplayList.sort((a, b) => b.confidence - a.confidence);
        }
        return finalDisplayList;
    };
    const highestValueSignal = sortSignals();
    if (highestValueSignal.length === 0) {
        return null;
    }

    return (
        <div className="d-flex flex-wrap pl-2">
            {highestValueSignal.map((signal, i) => (
                <Col key={signal.signalName} xs="auto" className="pr-1 pl-0 mb-1 cursor-pointer" onClick={() => handleSignalClick()}>
                    <ThreatSignalTooltip widgetName={signal.signalName} classes="no-white-space" tag />
                </Col>
            ))}
        </div>
    );
};

import React from 'react';
import { Tabs } from '../../Tabs';
import { Archived } from './ArchivedNotifications';
import { Inbox } from './InboxNotifications';

export const Notifications = () => (
    <div className="d-flex justify-content-center">
        <div className="mw-750 w-100 pb-3">
            <h3 className="my-3 font-size-24">Notifications</h3>
            <hr />
            <Tabs
                data={[
                    { name: 'Inbox',
                        id: 'inbox',
                        content: <Inbox /> },
                    { name: 'Archived',
                        id: 'archived',
                        content: <Archived /> }
                ]}
            />
        </div>
    </div>
);

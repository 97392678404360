import { useMutation } from '@apollo/client';
import { gql } from '../../generated';

type UseArchiveAlertProps = {
    alertIds: string[];
};

export const useArchiveAlert = ({ alertIds }: UseArchiveAlertProps) => {
    const [archiveAlert, { data, loading }] = useMutation(ARCHIVE_ALERT, {
        variables: {
            alertIds
        },
        update: (cache) => {
            alertIds.forEach((alertId) => {
                cache.modify({
                    id: cache.identify({
                        __typename: 'Alert',
                        id: alertId
                    }),
                    fields: {
                        archived: () => true
                    }
                });
            });
        },
        refetchQueries: ['getAllAlerts']
    });

    return {
        loading,
        archiveAlert,
        archived: data?.archiveAlert
    };
};

export const ARCHIVE_ALERT = gql(`
    mutation archiveAlert($alertIds: [String]!) {
        archiveAlert(alertIds: $alertIds)
    }
`);

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALERTS } from '../../../graphql';
import { NotificationCard } from './NotificationsCard';
import { Pagination } from '../../Molecules';
import { Loading } from '../../Loading';

type AlertSituation = {
    id: string,
    name: string
   }

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean,
    archived: boolean
};

export const Archived = () => {
    const [offset, setOffset] = useState(0);
    const { data, loading } = useQuery(GET_ALERTS, {
        variables: {
            offset,
            limit: 10,
            archived: true
        },
        fetchPolicy: 'cache-and-network'
    });
    const notifications = (data?.getAlerts?.alerts || []) as NotificationDefaultProps[];
    const total = data?.getAlerts?.total || 0;

    return (
        <div>
            {loading ? <Loading height={200} relative /> : (
                <>
                    <div className="d-flex my-3 ">
                        <p className="mb-0">{total} notifications</p>
                    </div>
                    <div className="noti-container px-3 pt-3 pb-2">
                        {notifications?.map(item => (
                            <NotificationCard notification={item} archive />
                        ))}
                    </div>
                    {total > 10 && (
                        <div className="bg-white">
                            <hr className="m-0" />
                            <Pagination total={total || 0}
                                itemsPerPage={10}
                                offset={offset}
                                setOffset={setOffset}
                                className="p-2 mb-0"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

import { useMutation } from '@apollo/client';
import moment from 'moment';
import { UPDATE_SITUATION_ROOM_DRAFT } from '../../graphql';
import { formatRoomDataToUpdate } from './formatToUpdate';

export const useUpdateSituationRoomDraft = ({ room }) => {
    const [updateSituationRoomDraft, { loading }] = useMutation(UPDATE_SITUATION_ROOM_DRAFT, {
        variables: formatRoomDataToUpdate({
            ...room,
            query: { included: room?.query?.included,
                excluded: room?.query?.excluded,
                boolean: room?.query?.boolean?.replace(/(?:[^\S\n]|\t)+/g, ' ').trim() },
            start_date: moment(room?.start_date).format('YYYY-MM-DD')
        }),
        update: (cache) => cache.evict({ fieldName: 'situationRoomDrafts' })
    });
    return {
        loading,
        updateSituationRoomDraft
    };
};

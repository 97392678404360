import React from 'react';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import { MoreVertical } from 'react-feather';
import { useUser } from '../../../context/user/UserContext';
import { useDropDown } from '../../../utils/useDropdown';
import { getRoom } from '../../../utils/variables';
import { ContentTableContentType } from './index';
import { useModal } from '../../../context/modal/ModalComponent';
import { ManageThreatsModal } from './ThreatSignals/ManageThreatsModal';

type ActionsProps = {
    key: string,
    value: React.ReactNode
}

type ContentActionsDropdownProps = {
    content: ContentTableContentType,
    onRemoveContent?: (data: { id: string }[]) => void
    onMarkAsThreat?: (id: string) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    onRequestInvestigation?: (content: ContentTableContentType) => void
    additionalActions?: ActionsProps []
}

export const ContentActionsDropdown = ({
    content,
    onRemoveContent,
    onMarkAsThreat,
    onMarkAsNotThreat,
    onRequestInvestigation,
    additionalActions = []
}: ContentActionsDropdownProps) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const { notAllowed }: any = useUser();
    const [dropdown, toggleDropdown] = useDropDown();
    const instancePlan = room.instance?.plan;

    const isThreat = content?.threatObject?.threatLevel ? content?.threatObject?.threatLevel !== 'none' : false;
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;
    const handleManageThreats = () => {
        setModal({
            header: false,
            size: 'lg',
            component: <ManageThreatsModal selectedContents={[content]} clearSelection={closeModal} singleContent isMatch={!isThreat} />,
        });
    };

    if (notAllowed.includes('editData') || !instancePlan?.others?.contentActions) return null;

    return (
        <span>
            <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                className="d-inline"
                isOpen={dropdown}
                toggle={toggleDropdown}
                tag="div"
                direction={window.innerWidth < 540 ? 'left' : 'right'}
                data-testid="content-list-item-actions"
            >
                <DropdownToggle className="bg-transparent border-0 mw-0 px-0" data-testid="content-list-item-actions-toggle">
                    <Button color="link" className="p-0 mw-0" tag="div" size="xs">
                        <MoreVertical data-testid="ellipses-vertical-icon" />
                    </Button>
                </DropdownToggle>
                <DropdownMenu>
                    {isThreat && (
                        <>
                            {onRequestInvestigation && (
                                <DropdownItem onClick={() => onRequestInvestigation?.(content)}>
                                    <span>Investigate</span>
                                </DropdownItem>
                            )}
                            {(onMarkAsNotThreat && !threatSignalsEnabled) && (
                                <DropdownItem onClick={() => onMarkAsNotThreat?.([content.id])}>
                                    <span>Mark as not threat</span>
                                </DropdownItem>
                            )}
                            {threatSignalsEnabled
                                && (
                                    <DropdownItem onClick={() => handleManageThreats()}>
                                        <span>Manage threats</span>
                                    </DropdownItem>
                                )}
                        </>
                    )}
                    {(!isThreat && onMarkAsThreat && !threatSignalsEnabled) && (
                        <DropdownItem onClick={() => onMarkAsThreat?.(content.id)}>
                            <span>Mark as threat</span>
                        </DropdownItem>
                    )}
                    {threatSignalsEnabled && !isThreat
                                && (
                                    <DropdownItem onClick={() => handleManageThreats()}>
                                        <span>Manage threats</span>
                                    </DropdownItem>
                                )}
                    {onRemoveContent && (
                        <DropdownItem onClick={() => onRemoveContent?.([{ id: content.id }])}>
                            <span>Remove content</span>
                        </DropdownItem>
                    )}
                    {additionalActions.map(action => (
                        <DropdownItem key={action.key}>
                            {action.value}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </span>
    );
};
